import { createStore } from '@stencil/store';
import { addMonths } from 'date-fns';
import type {
  AccountDocument,
  BudgetDocument,
  BudgetsById,
  GroupDocument,
  ProjectionWithAvailable,
} from '@og-shared/types';
import {
  getToday,
  calcFrequencyFromBudget,
  budgetNotTracked,
  formatDate,
  keys,
} from '@og-shared/utils';

import { getProjectedTransactionsByBudgetId } from '../global/projections';
import { balanceToReachGoal, getBudgetsByIdFromBudgets } from '../global/utils';
import { getDefaultBudgets } from '../services/static-data';
import { groupState } from './group.store';
import { setBudgetViewById } from './budget-view.store';
import {
  getBudgetViewByBudgetType,
  getBudgetViewIncomeVsExpenses,
} from '../global/budget-view-utils';

const initialState: BudgetsState = {
  accounts: {
    accounts_count: 0,
    total_accounts_available: 0,
    total_accounts_debt: 0,
    total_accounts_cash: 0,
    accounts_by_id: {},
    accounts_loaded: false,
    included_account_ids: [],
    not_included_account_ids: [],
    onboarding: {
      link_accounts: false,
    },
  },
  budgets: {
    budgetCount: 0,
    badges: {
      INCOME: 0,
      BILL: 0,
      SAVINGS: 0,
      SPENDING: 0,
      ALL: 0,
    },
    budgets_by_id: {},
    budgets_loaded: false,
    budget_totals: {
      INCOME: 0,
      BILL: 0,
      SAVINGS: 0,
      SPENDING: 0,
      ALL: 0,
    },
    onboarding: {
      create_budgets: false,
      fund_budgets: false,
    },
    sorted_budget_ids: {
      INCOME: [],
      BILL: [],
      SAVINGS: [],
      SPENDING: [],
      ALL: [],
    },
    budget_types: {
      INCOME: true,
      SAVINGS: true,
      BILL: true,
      SPENDING: true,
    },
    income_available_weeks: 0,
    weekly_budget_totals: {
      INCOME: 0,
      BILL: 0,
      SAVINGS: 0,
      SPENDING: 0,
      ALL: 0,
    },
  },
  changing_fill: false,
  edit_budget: null as any,
  edit_budget_cash_flow: 0,
  group_fill_day: 0,
  items: {
    items_by_id: {},
    item_errors: 0,
    items_loaded: false,
    item_count: 0,
  },
  plan_mode: false,
  reordering: false,
  transactions: {
    transactions_loaded: false,
    uncategorized_total: 0,
    uncategorized_transactions: [],
    uncategorized_count: 0,
  },
};
export const {
  state: budgetsState,
  onChange: onBudgetStoreChange,
  reset: resetBudgetsState,
  dispose: disposeBudgetsStore,
  on: onBudgetStore,
} = createStore<BudgetsState>(initialState);

///////////////
// supporting functions

function getIncomeAvailableWeeks(
  income_available: number,
  total_weekly_budgets: number
) {
  if (total_weekly_budgets === 0 && income_available === 0) return 0;
  const weeks = Math.floor(income_available / total_weekly_budgets);
  return weeks;
}

function getExpectedBalanceFromProjections(
  auto_fill: boolean,
  budget: BudgetDocument,
  budget_projections: ProjectionWithAvailable[]
) {
  if (!auto_fill && budget.per_year === 12) {
    // auto-fill off
    // ignore monthly bills - paid per paycheck
    return {
      expected_balance: 0,
      below_zero_date: null,
    };
  }
  if (!budget_projections) {
    return {
      expected_balance: 0,
      below_zero_date: null,
    };
  }

  let lowest_balance: number | undefined;
  let prev_available = 0;

  let below_zero_date: string | null = null;
  let above_zero_date: string | null = null;
  budget_projections.map((projection, index) => {
    if (prev_available > 0 && projection.available < 0) {
      if (!below_zero_date) {
        below_zero_date = projection.date;
        // console.log(
        //   budget.name,
        //   'will go below zero for the first time on',
        //   below_zero_date
        // );
      } else {
        // console.log(budget.name, 'will go below zero on', below_zero_date);
      }
    }
    if (prev_available < 0 && projection.available > 0) {
      above_zero_date = projection.date;
      // console.log(budget.name, 'will go above zero on', above_zero_date);
    } else if (projection.available < 0) {
      // above_zero_date must stay above zero - if it's below - reset it.
      above_zero_date = null;
    }
    prev_available = projection.available;
    if (
      index === 0 ||
      (lowest_balance !== undefined && projection.available < lowest_balance)
    ) {
      lowest_balance = projection.available;
    }
  });
  const expected_balance = budget.available - (lowest_balance ?? 0);
  // console.log(
  //   `current balance - lowest balance = expected balance: ${budget.available} - ${lowest_balance} = ${expected_balance}`
  // );
  return { expected_balance, below_zero_date, above_zero_date };
}

function getExpectedBalance(
  budget: BudgetDocument,
  fillDate: GroupDocument['fill_day']
) {
  if (
    budget.type === 'SAVINGS' &&
    budget.is_goal &&
    budget.per_year === 0 &&
    budget.next_transaction_date &&
    budget.budget &&
    budget.fill_amount
  ) {
    return balanceToReachGoal(
      budget.next_transaction_date,
      budget.budget,
      budget.fill_amount,
      fillDate
    );
  } else {
    return 0;
  }
}

function budgetShouldBeBadged(budget: BudgetDocument, today: string) {
  if (budget.available < 0) {
    return true;
  } else if (
    budget.expected_balance &&
    budget.expected_balance > budget.available
  ) {
    // off Track - only show badge if auto funding is on
    return groupState.groupDoc.auto_fill;
  } else if (isPassedDue(budget, today)) {
    return true;
  } else if (budget.type === 'BILL' && !budget.next_transaction_date) {
    return true;
  } else {
    return false;
  }
}

function isPassedDue(budget: BudgetDocument, today: string) {
  return (
    (budget.type == 'INCOME' || budget.type == 'BILL') &&
    budget.budget_id !== 'INCOME' &&
    budget.next_transaction_date &&
    today >= budget.next_transaction_date
  );
}

// function noFillDays(fill_days: BudgetDocument['fill_days']) {
//   let noFill = true;
//   if (!fill_days || !fill_days.length) return true;
//   fill_days.map(day => {
//     if (day) {
//       noFill = false;
//     }
//   });
//   return noFill;
// }

export function updateBudgetsState(params: {
  budgets_by_id: BudgetsById;
  sorted_budget_ids: BudgetsState['budgets']['sorted_budget_ids'];
  budgets_loaded: boolean;
  group_fill_day: BudgetsState['group_fill_day'];
  auto_fill: GroupState['groupDoc']['auto_fill'];
  todayString: string; // used for checking cashflow today
}) {
  const {
    budgets_by_id,
    budgets_loaded,
    sorted_budget_ids,
    group_fill_day,
    auto_fill,
    todayString,
  } = params;
  const projectTo = formatDate(addMonths(getToday(), 14)); // 14 months into the future
  const badges: BudgetsState['budgets']['badges'] = {
    INCOME: 0,
    BILL: 0,
    SAVINGS: 0,
    SPENDING: 0,
    ALL: 0,
  };

  const budget_totals: BudgetsState['budgets']['budget_totals'] = {
    INCOME: 0,
    BILL: 0,
    SAVINGS: 0,
    SPENDING: 0,
    ALL: 0,
  };
  const budget_types: BudgetsState['budgets']['budget_types'] = {
    INCOME: true,
    SAVINGS: false,
    BILL: false,
    SPENDING: false,
  };
  let fund_budgets = false;
  let create_budgets = false;
  let budgetCount = 0;
  const projectionsById = getProjectedTransactionsByBudgetId(
    budgets_by_id,
    [],
    group_fill_day,
    todayString,
    projectTo
  );
  keys(budgets_by_id).map(budget_id => {
    if (budgetNotTracked(budget_id)) return;
    const budget = budgets_by_id[budget_id];
    budgets_by_id[budget_id].below_zero_date = null;
    budgets_by_id[budget_id].expected_balance = 0;
    budget_types[budget.type] = true;
    if (!fund_budgets && budget.available !== 0 && budget_id !== 'INCOME') {
      fund_budgets = true;
    }
    budgetCount++;
    if (budget.budget_id !== 'INCOME') {
      // runway budget is created automatically
      // we use this to show analyze spending onboarding
      // so that if the user already created categories, we don't create them again
      create_budgets = true;
    }
    if (budget.type === 'BILL' || budget_id === 'INCOME') {
      const { expected_balance, below_zero_date, above_zero_date } =
        getExpectedBalanceFromProjections(
          auto_fill,
          budget,
          projectionsById[budget.budget_id]
        );
      budgets_by_id[budget.budget_id].expected_balance = expected_balance;
      budgets_by_id[budget.budget_id].below_zero_date = below_zero_date;
      budgets_by_id[budget.budget_id].above_zero_date = above_zero_date;
    } else {
      budgets_by_id[budget.budget_id].expected_balance = getExpectedBalance(
        budget,
        group_fill_day
      );
    }
    if (budgetShouldBeBadged(budget, todayString)) {
      badges.ALL = badges.ALL + 1;
      badges[budget.type] = badges[budget.type] + 1;
    }
    if (budget.type !== 'INCOME') {
      budget_totals[budget.type] =
        budget_totals[budget.type] + budget.available;
      budget_totals.ALL = budget_totals.ALL + budget.available;
    } else {
      if (budget.budget_id === 'INCOME') {
        budget_totals.INCOME = budget.available;
        budget_totals.ALL = budget_totals.ALL + budget.available;
      } else {
        //
      }
    }
  });
  const weekly_budget_totals = getWeeklyBudgetTotals(
    budgets_by_id,
    todayString
  );

  const income_available_weeks = getIncomeAvailableWeeks(
    budget_totals.INCOME,
    weekly_budget_totals.BILL +
      weekly_budget_totals.SAVINGS +
      weekly_budget_totals.SPENDING
  );

  budgetsState.budgets = {
    budgetCount,
    budget_totals,
    budgets_by_id,
    income_available_weeks,
    weekly_budget_totals,
    badges,
    sorted_budget_ids,
    budgets_loaded,
    budget_types,
    onboarding: {
      create_budgets,
      fund_budgets,
    },
  };
}

export function getWeeklyBudgetTotals(
  budgets_by_id: BudgetsById,
  todayString: string,
  exclude_ids: string[] = []
) {
  const weekly_budget_totals: BudgetsState['budgets']['weekly_budget_totals'] =
    {
      INCOME: 0,
      BILL: 0,
      SAVINGS: 0,
      SPENDING: 0,
      ALL: 0,
    };
  keys(budgets_by_id).map(budget_id => {
    if (budgetNotTracked(budget_id)) return;
    if (exclude_ids.includes(budget_id)) return;
    const budget = budgets_by_id[budget_id];
    if (exclude_ids.includes(budget.type)) return;

    if (budget.type !== 'INCOME') {
      weekly_budget_totals[budget.type] =
        weekly_budget_totals[budget.type] +
        calcFrequencyFromBudget({ budget, frequency: 52, todayString });
      weekly_budget_totals.ALL =
        weekly_budget_totals.ALL -
        calcFrequencyFromBudget({ budget, frequency: 52, todayString });
    } else {
      if (budget.budget_id === 'INCOME') {
        //
      } else {
        weekly_budget_totals.INCOME =
          weekly_budget_totals.INCOME +
          calcFrequencyFromBudget({ budget, frequency: 52, todayString });
        weekly_budget_totals.ALL =
          weekly_budget_totals.ALL +
          calcFrequencyFromBudget({ budget, frequency: 52, todayString });
      }
    }
  });
  return weekly_budget_totals;
}

export function setBudgets(budgets: BudgetDocument[], todayString: string) {
  const sorted_budget_ids = sortBudgets(budgets);
  updateBudgetsState({
    sorted_budget_ids,
    budgets_loaded: true,
    budgets_by_id: getBudgetsById(budgets),
    auto_fill: groupState.groupDoc.auto_fill,
    group_fill_day: groupState.groupDoc.fill_day,
    todayString,
  });
  setBudgetViewById(getBudgetViewByBudgetType(sorted_budget_ids));
  setBudgetViewById(getBudgetViewIncomeVsExpenses(sorted_budget_ids));
}

function getBudgetsById(budgets: BudgetDocument[]) {
  const budgets_by_id = getBudgetsByIdFromBudgets(budgets);
  keys(getDefaultBudgets()).map(budget_id => {
    budgets_by_id[budget_id] = { ...getDefaultBudgets()[budget_id] };
  });
  return budgets_by_id;
}

export function sortBudgets(budgets: BudgetDocument[]) {
  const sorted_budget_ids: BudgetsState['budgets']['sorted_budget_ids'] = {
    INCOME: budgets
      .filter(
        budget => budget.type === 'INCOME' && budget.budget_id !== 'INCOME'
      )
      .sort(sortNextTransaction)
      .map(budget => budget.budget_id),
    SPENDING: budgets
      .filter(budget => budget.type === 'SPENDING')
      .sort(sortOrder)
      .map(budget => budget.budget_id),
    SAVINGS: budgets
      .filter(budget => budget.type === 'SAVINGS')
      .sort(sortOrder)
      .map(budget => budget.budget_id),
    BILL: budgets
      .filter(budget => budget.type === 'BILL')
      .sort(sortNextTransaction)
      .map(budget => budget.budget_id),
    ALL: budgets.map(budget => budget.budget_id),
  };

  return sorted_budget_ids;
}

const sortOrder = (a: BudgetDocument, b: BudgetDocument) => {
  if (!b.order && b.order !== 0) return -1;
  if (!a.order && a.order !== 0) return 1;
  return a.order - b.order;
};

const sortNextTransaction = (a: BudgetDocument, b: BudgetDocument) => {
  if (
    (a.next_transaction_date &&
      b.next_transaction_date &&
      a.next_transaction_date < b.next_transaction_date) ||
    !b.next_transaction_date
  ) {
    return -1;
  } else if (
    (a.next_transaction_date &&
      b.next_transaction_date &&
      a.next_transaction_date > b.next_transaction_date) ||
    !a.next_transaction_date
  ) {
    return 1;
  } else {
    return 0;
  }
};

export function getAccountStatus(account: AccountDocument) {
  if (account.status === 'NOT_CONNECTED') {
    return 'link-error';
  }
  if (
    account &&
    account.manual_entry === false &&
    account.last_webhook === null
  ) {
    return 'loading';
  }
  return 'linked';
}
